<template>
  <div id="view-setting">
    <Topbar :backTo="{ name: 'Site' }">Taipei Office 區域設定</Topbar>
    <div class="content-container">
      <SideNav>
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditGeneral', params: $route.params.id }"
          >屬性設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'GroupSetting', params: $route.params.id }"
          >網段群組設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'DHCPList', params: $route.params.id }"
          >註冊的 IP 派發來源</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditBlockMessage', params: $route.params.id }"
          >封鎖訊息 &amp; 驗證方式</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'ADAutoRegisterSetting', params: $route.params.id }"
          >AD 自動授權設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'RadiusSiteConfigSetting', params: $route.params.id }"
          >802.1x 設定</router-link
        >
      </SideNav>
      <div class="setting-container">
        <div class="main-list-view setting-list-view darken-list">
          <!-- 右側：列表容器 -->
          <div class="main-list-container">
            <div class="title-wrapper">
              <h2 class="title">註冊的 IP 派發來源</h2>
            </div>
            <!-- 上：控制列容器 -->
            <ControlWrapper>
              <ControlButton
                iconName="plus"
                text="新增 IP 派發來源"
                @click="toggleNewIpSource"
              />
            </ControlWrapper>
            <!-- 下：表格容器 -->
            <GridWrapper>
              <GridPageInfo :skip="skip" :take="take" :total="total" />
              <Grid
                :style="{ height: 'calc(100vh - 350px)' }"
                :columns="columns"
                :data-items="result"
                :pageable="pageable"
                :skip="skip"
                :take="take"
                :total="total"
                @pagechange="pageChangeHandler"
                :scrollable="'true'"
                :sortable="false"
                @toggleEditIpSource="toggleEditIpSource"
              />
            </GridWrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container">
    <Dialog
      v-if="showEditIpSource"
      :title="'編輯 IP 派發來源'"
      :width="930"
      :height="480"
      @close="toggleEditIpSource"
    >
      <EditIpSourceForm @close="toggleEditIpSource" />
    </Dialog>
    <Dialog
      v-if="showNewIpSource"
      :title="'新增 IP 派發來源'"
      :width="930"
      :height="480"
      @close="toggleNewIpSource"
    >
      <NewIpSourceForm @close="toggleNewIpSource" />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import useGridInDHCPList from '@/composable/useGridInDHCPList'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import EditIpSourceForm from '@/components/Form/DHCPListView/EditIpSourceForm.vue'
import NewIpSourceForm from '@/components/Form/DHCPListView/NewIpSourceForm.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    ControlWrapper,
    ControlButton,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    EditIpSourceForm,
    NewIpSourceForm
  },
  setup() {
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInDHCPList()

    onMounted(() => {
      dataItems.value = createRandomData(32) as never[]
    })

    // Dialog 相關
    const { show: showEditIpSource, toggle: toggleEditIpSource } = useToggle()
    const { show: showNewIpSource, toggle: toggleNewIpSource } = useToggle()

    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      // Dialog 相關
      showEditIpSource,
      toggleEditIpSource,
      showNewIpSource,
      toggleNewIpSource
    }
  }
})
</script>

<style scoped></style>
