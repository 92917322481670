
import { defineComponent, ref } from 'vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'

export default defineComponent({
  components: {
    FeildInputGroup,
    KendoInput: Input,
    DropDownList
  },
  setup() {
    // 備註
    const notes = ref('註冊 IP 派發來源 - Henry')
    // 類型
    const typeValue = ref(2)
    const typeArray = ref([
      { name: 'None', value: 0 },
      { name: 'DHCP', value: 1 },
      { name: 'SLACC', value: 2 }
    ])
    // 提交表單
    const submitForm = () => {
      // FIXME: 做後續處理
    }
    return {
      notes,
      typeValue,
      typeArray,
      submitForm
    }
  }
})
