import { ref, computed, markRaw } from 'vue'

// Cells
import IP from '../components/Grid/Cell/DHCPListView/IP.vue'
import Operation from '../components/Grid/Cell/DHCPListView/Operation.vue'
import OperationHeader from '../components/Grid/Cell/DHCPListView/OperationHeader.vue'

// 區域設定/註冊的 IP 派發來源列表中使用 Kendo Grid
const useGridInDHCPList = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)

  // Header 欄位
  const columns = ref([
    {
      field: 'IP',
      title: 'IP',
      width: '240px',
      cell: markRaw(IP)
    },
    {
      field: 'Operation',
      title: '操作',
      width: '85px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader)
    },
    {
      field: 'Notes',
      title: '備註',
      width: '260px'
    },
    {
      field: 'Details',
      title: '備註',
      width: 'auto'
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    return dataItemsWithPage
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const operation = [
      { isDeletable: true },
      { isDeletable: true },
      { isDeletable: false }
    ]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        IP: '2001:b030:1328:1061::61:253',
        Operation: operation[Math.floor(Math.random() * operation.length)],
        Notes: '註冊 IP 派發來源 - Henry',
        Details: 'Original IP: 192.168.111.111'
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    createRandomData,
    pageChangeHandler
  }
}

export default useGridInDHCPList
